import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './style-global.css';
import { isProd } from './api/RequestHandler';

const root = createRoot(document.getElementById('root') as HTMLElement);

const audience = isProd ? process.env.REACT_APP_AUTH_AUDIENCE : 'http://localhost:8080';

if (!audience) throw new Error('No audience env set');

root.render(
  <Auth0Provider
    domain="ecometrix.eu.auth0.com"
    clientId="6zEaSnPoxu5MkItYpMJy9WAKAMZ4i3Yt"
    authorizationParams={{
      audience: audience,
      redirect_uri: window.location.origin,
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);
