import { Carousel, Panel } from 'rsuite';

const Home = () => {
  return (
    <div>
      <h2>Welcome to the exometrix EDP service!</h2>
      <h4>Below you can see a preview of what a membership here gives you access to!</h4>
      <Panel bordered style={{ margin: '0px 80px' }}>
        <Carousel style={{ height: '600px' }} autoplay className="custom-slider">
          <img src="/first_image.png" height="500px" />
          <img src="/second_image.png" height="500px" />
          <img src="/third_image.png" height="500px" />
        </Carousel>
      </Panel>
    </div>
  );
};

export default Home;
