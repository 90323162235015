import { SetStateAction, useState } from 'react';
import { Category } from '../../interfaces';
import { Button, Input, InputGroup, Message, Modal, Panel, useToaster } from 'rsuite';
import * as uuid from 'uuid';
import RemindIcon from '@rsuite/icons/legacy/Remind';
import { useAuth0 } from '@auth0/auth0-react';
import { createCategory, deleteCategory, getCategories, updateCategory } from '../../api/RequestHandler';

interface props {
  setCategories: React.Dispatch<SetStateAction<Category[]>>;
  itemWidth: string;
  category?: Category;
}

const CategoryItem = (props: props) => {
  const { setCategories, itemWidth, category } = props;
  const [editedCategory, setEditedCategory] = useState(
    category ||
      ({
        id: uuid.v4(),
        name: '',
        description: '',
        tableDataTypeId: '',
        aliases: [],
      } as Category)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleChange = (field: keyof Category, value: any) => {
    setEditedCategory((prevConfig) => ({
      ...prevConfig,
      [field]: value,
    }));
  };

  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();

  const onSubmit = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      category ? await updateCategory(token, editedCategory) : await createCategory(token, editedCategory);

      setLoading(false);
      toaster.push(
        <Message showIcon type="info" closable>
          {'Updated KPI config!'}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    } catch (error) {
      setLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    }
  };

  const onDelete = async () => {
    setShowAlert(false);
    setDeleteLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const id = await deleteCategory(token, editedCategory);
      setCategories((oldCategories: Category[]) => oldCategories.filter((category) => category.id !== id));
      toaster.push(
        <Message showIcon type="info" closable>
          Config deleted!
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    }
  };

  return (
    <div style={{ flex: `0 0 ${itemWidth}`, margin: '10px' }}>
      {
        <Modal backdrop="static" role="alertdialog" open={showAlert} onClose={() => setShowAlert(false)} size="xs">
          <Modal.Body>
            <RemindIcon style={{ color: '#ffb300', fontSize: 24, marginBottom: '12px' }} />
            <p>The field will be deleted permanently!</p>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onDelete} color="red" appearance="primary">
              Delete!
            </Button>
            <Button onClick={() => setShowAlert(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      }
      <Panel header={editedCategory.name || (!category ? 'Add new' : 'No name')} collapsible bordered>
        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Name</InputGroup.Addon>
          <Input value={editedCategory.name} onChange={(value) => handleChange('name', value)} />
        </InputGroup>
        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Description</InputGroup.Addon>
          <Input
            as="textarea"
            rows={3}
            value={editedCategory.description}
            onChange={(value) => handleChange('description', value)}
          />
        </InputGroup>
        <div style={{ display: 'flex' }}>
          {!category ? (
            <></>
          ) : (
            <>
              <div style={{ flex: 1 }} />
              <Button
                loading={deleteLoading}
                style={{ marginTop: '20px' }}
                color="red"
                appearance="primary"
                onClick={() => setShowAlert(true)}
              >
                Delete
              </Button>
            </>
          )}

          <div style={{ flex: 1 }} />
          <Button
            disabled={!category}
            loading={loading}
            style={{ marginTop: '20px' }}
            color="green"
            appearance="primary"
            onClick={onSubmit}
          >
            {!category ? 'Disabled feature' : 'Save'}
          </Button>
          <div style={{ flex: 1 }} />
        </div>
      </Panel>
    </div>
  );
};

export default CategoryItem;
