import { useState } from 'react';
import { ScrapeConfig } from '../../interfaces';
import { Button, Input, InputGroup, Message, Modal, Panel, useToaster } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import { createScrapeConfig, deleteScrapeConfig, updateScrapeConfig } from '../../api/RequestHandler';
import RemindIcon from '@rsuite/icons/legacy/Remind';

interface Props {
  config: ScrapeConfig;
  setConfigs: Function;
  itemWidth: string;
}

const ScraperConfigItem: React.FC<Props> = (props: Props) => {
  const { config, itemWidth } = props;

  const [editedConfig, setEditedConfig] = useState<ScrapeConfig>({ ...config });
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();

  const toaster = useToaster();

  const onSubmit = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await updateScrapeConfig(token, editedConfig);
      setLoading(false);
      toaster.push(
        <Message showIcon type="info" closable>
          {'Updated scraper config!'}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    } catch (error) {
      setLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    }
  };

  const handleChange = (field: keyof ScrapeConfig, value: any) => {
    setEditedConfig((prevConfig) => ({
      ...prevConfig,
      [field]: value,
    }));
  };

  const onDelete = async () => {
    setShowAlert(false);
    setDeleteLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const id = await deleteScrapeConfig(token, editedConfig);
      props.setConfigs((oldConfigs: ScrapeConfig[]) => oldConfigs.filter((config) => config.id !== id));
      toaster.push(
        <Message showIcon type="info" closable>
          Config deleted!
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    }
  };

  return (
    <div style={{ flex: `0 0 ${itemWidth}`, margin: '10px' }}>
      <Modal backdrop="static" role="alertdialog" open={showAlert} onClose={() => setShowAlert(false)} size="xs">
        <Modal.Body>
          <RemindIcon style={{ color: '#ffb300', fontSize: 24, marginBottom: '12px' }} />
          <p>The field will be deleted permanently!</p>
          <p>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onDelete} color="red" appearance="primary">
            Delete!
          </Button>
          <Button onClick={() => setShowAlert(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Panel header={editedConfig.companyName || 'No name'} collapsible bordered>
        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Company</InputGroup.Addon>
          <Input value={editedConfig.companyName} onChange={(value) => handleChange('companyName', value)} />
        </InputGroup>
        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Description</InputGroup.Addon>
          <Input value={editedConfig.description} onChange={(value) => handleChange('description', value)} />
        </InputGroup>

        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Industry</InputGroup.Addon>
          <Input value={editedConfig.industry} onChange={(value) => handleChange('industry', value)} />
        </InputGroup>

        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Url</InputGroup.Addon>
          <Input value={editedConfig.url} onChange={(value) => handleChange('url', value)} />
        </InputGroup>

        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Regex</InputGroup.Addon>
          <Input value={editedConfig.regex} onChange={(value) => handleChange('regex', value)} />
        </InputGroup>

        <InputGroup>
          <InputGroup.Addon>CSS Selector</InputGroup.Addon>
          <Input value={editedConfig.cssSelector || ''} onChange={(value) => handleChange('cssSelector', value)} />
        </InputGroup>

        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }} />
          <Button
            loading={deleteLoading}
            style={{ marginTop: '20px' }}
            color="red"
            appearance="primary"
            onClick={() => setShowAlert(true)}
          >
            Delete
          </Button>
          <div style={{ flex: 1 }} />
          <Button loading={loading} style={{ marginTop: '20px' }} color="green" appearance="primary" onClick={onSubmit}>
            Save
          </Button>
          <div style={{ flex: 1 }} />
        </div>
      </Panel>
    </div>
  );
};

interface PropsNew {
  setConfigs: Function;
  configs: ScrapeConfig[];
  itemWidth: string;
}

export const ScraperConfigItemNew: React.FC<PropsNew> = (props: PropsNew) => {
  const emptyConfig = {
    companyName: '',
    description: '',
    regex: '',
    url: '',
    cssSelector: '',
    industry: '',
  } as ScrapeConfig;

  const [config, setConfig] = useState<ScrapeConfig>({ ...emptyConfig });
  const [loading, setLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  const toaster = useToaster();

  const onSubmit = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const id = await createScrapeConfig(token, config);
      setLoading(false);
      config.id = id;

      props.setConfigs((configs: ScrapeConfig[]) => [...configs, config]);
      setConfig(emptyConfig);
      toaster.push(
        <Message showIcon type="info" closable>
          {'Updated scraper config!'}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    } catch (error) {
      setLoading(false);
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    }
  };

  const handleChange = (field: keyof ScrapeConfig, value: any) => {
    setConfig((config) => ({
      ...config,
      [field]: value,
    }));
  };

  return (
    <div style={{ flex: `0 0 ${40}`, padding: '30px' }}>
      <Panel header={config.companyName || 'Add new'} collapsible bordered>
        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Company</InputGroup.Addon>
          <Input value={config.companyName} onChange={(value) => handleChange('companyName', value)} />
        </InputGroup>
        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Description</InputGroup.Addon>
          <Input value={config.description} onChange={(value) => handleChange('description', value)} />
        </InputGroup>

        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Industry</InputGroup.Addon>
          <Input value={config.industry} onChange={(value) => handleChange('industry', value)} />
        </InputGroup>

        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Url</InputGroup.Addon>
          <Input value={config.url} onChange={(value) => handleChange('url', value)} />
        </InputGroup>

        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Regex</InputGroup.Addon>
          <Input value={config.regex} onChange={(value) => handleChange('regex', value)} />
        </InputGroup>

        <InputGroup>
          <InputGroup.Addon>CSS Selector</InputGroup.Addon>
          <Input value={config.cssSelector || ''} onChange={(value) => handleChange('cssSelector', value)} />
        </InputGroup>

        <Button loading={loading} style={{ marginTop: '20px' }} color="green" appearance="primary" onClick={onSubmit}>
          Add
        </Button>
      </Panel>
    </div>
  );
};

export default ScraperConfigItem;
