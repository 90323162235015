import { Product, TextField } from '../../interfaces';
import { Button, Panel } from 'rsuite';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import TextFieldItem from './TextFieldItem';

interface Props {
  product: Product;
}

const DetailedProductTextFields: React.FC<Props> = (props: Props) => {
  const { product } = props;
  const [fields, setFields] = useState<TextField[]>(product.textFields || []);
  const [showNew, setShowNew] = useState<boolean>(false);

  const { isAuthenticated, user } = useAuth0();
  let isAdmin = false;
  if (user && isAuthenticated) {
    const roles = user['auth-roles'];
    isAdmin = roles.includes('super-admin');
  }

  return (
    <Panel bordered>
      <h3>Texts</h3>
      {isAdmin ? (
        <>
          <Button
            color="green"
            appearance="primary"
            style={{ marginBottom: '10px' }}
            onClick={() => {
              setShowNew((old) => !old);
            }}
          >
            {showNew ? 'Close new' : 'Add new'}
          </Button>
          {!showNew ? (
            <></>
          ) : (
            <div>
              <TextFieldItem productId={product.id} setFields={setFields}></TextFieldItem>
              <hr />
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
        {fields.map((field) => (
          <TextFieldItem key={field.id} field={field} productId={product.id} setFields={setFields} />
        ))}
      </div>
    </Panel>
  );
};

export default DetailedProductTextFields;
