import React, { useState, ChangeEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getSignedURL } from '../api/RequestHandler';

const Upload = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [file, setFile] = useState<File | null>(null);
  const [customFileName, setCustomFileName] = useState<string>('');
  const [feedback, setFeedback] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];

      // Check if the selected file is a PDF
      if (selectedFile.type !== 'application/pdf') {
        alert('Please select a PDF file');
        // Clear the selected file and disable the file input
        e.target.value = '';
        setFile(null);
        return;
      }

      setFile(selectedFile);
    }
  };

  const handleFileNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomFileName(e.target.value);
  };

  const handleCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  };

  const handleIndustryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIndustry(e.target.value);
  };

  const { isAuthenticated, user } = useAuth0();

  let isAdmin = false;
  if (user && isAuthenticated) {
    const roles = user['auth-roles'];
    isAdmin = roles.includes('super-admin');
  }

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file');
      return;
    }

    if (!companyName) {
      alert('Please add a Company Name');
      return;
    }
    if (!industry) {
      alert('Please add a Industry');
      return;
    }

    // Use the customFileName if provided, otherwise use the original file name
    let fileNameToUse = customFileName || file.name;

    // Check if the customFileName ends with ".pdf" and append it if not
    if (!fileNameToUse.toLowerCase().endsWith('.pdf')) {
      fileNameToUse += '.pdf';
    }
    const metadata = { company: companyName, industry: industry };

    const token = await getAccessTokenSilently();
    const response = await getSignedURL(token, fileNameToUse, 'application/pdf', metadata);

    if (!response.url) {
      setFeedback('Failed to obtain signed URL');
      return;
    }

    const signedUrl = await response.url;
    const uploadResponse = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
        'x-amz-meta-company': companyName,
        'x-amz-meta-industry': industry,
      },
    });

    if (uploadResponse.ok) {
      setFeedback('File uploaded successfully.');
    } else {
      setFeedback('File upload failed.');
    }
  };

  return isAdmin ? (
    <div>
      Required: <input type="file" accept=".pdf" onChange={handleFileChange} />
      Custom file name:{' '}
      <input
        style={{ marginRight: '10px' }}
        type="text"
        placeholder="Enter custom file name"
        value={customFileName}
        onChange={handleFileNameChange}
      />
      Company Name:{' '}
      <input
        style={{ marginRight: '10px' }}
        type="text"
        placeholder="Enter company name"
        value={companyName}
        onChange={handleCompanyNameChange}
      />
      Industry:{' '}
      <input
        style={{ marginRight: '10px' }}
        type="text"
        placeholder="Enter industry"
        value={industry}
        onChange={handleIndustryChange}
      />
      <button onClick={handleUpload}>Upload</button>
      {feedback && <p>{feedback}</p>}
    </div>
  ) : (
    <div>
      <p>You don't have permission to upload files.</p>
    </div>
  );
};

export default Upload;
