import { useEffect, useState } from 'react';
import { Category } from '../../interfaces';
import { Loader, Message, Panel, useToaster } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import { getCategories } from '../../api/RequestHandler';
import CategoryItem from './CategoryItem';

const Categories = () => {
  const toaster = useToaster();
  const { getAccessTokenSilently } = useAuth0();

  const [categories, setCategories] = useState<Category[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loading) {
      try {
        getAccessTokenSilently().then(async (token) => {
          const categories = await getCategories(token);
          setCategories(categories);
          setLoading(false);
        });
      } catch (error) {
        toaster.push(
          <Message showIcon type="error" closable>
            {'' + error}
          </Message>,
          { placement: 'bottomEnd', duration: 2000 }
        );
      }
    }
  });

  const items_per_row = 3;
  const paddingSize = 20;

  // Calculate the width of each item and include the paddings
  const itemWidth = `calc((100% - ${paddingSize * items_per_row}px) / ${items_per_row})`;

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' }}>
      <Panel shaded bordered style={{ width: '100%' }}>
        <h3>KPIs</h3>

        {!loading ? (
          <div style={{ padding: '10px', display: 'flex', flexWrap: 'wrap' }}>
            {/* <div style={{margin: '10px', width: "100%"}}>
            <CategoryItem
              setCategories={setCategories}
              itemWidth={"calc(100% - 20px)"} 
            />
          </div>
            <br/> */}
            {categories.map((category, index) => (
              <div key={index} style={{ flex: `0 0 ${itemWidth}`, margin: '10px' }}>
                <CategoryItem
                  key={category.id}
                  category={category}
                  setCategories={setCategories}
                  itemWidth={itemWidth}
                ></CategoryItem>
              </div>
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </Panel>
    </div>
  );
};

export default Categories;
