import { Button, Input, InputGroup, Message, Modal, Panel, useToaster } from 'rsuite';
import { TextField } from '../../interfaces';
import { useState } from 'react';
import RemindIcon from '@rsuite/icons/legacy/Remind';

import { useAuth0 } from '@auth0/auth0-react';
import { addTextField, deleteTextField, updateTextField } from '../../api/RequestHandler';

interface props {
  field?: TextField;
  productId: string;
  setFields: Function;
}

const TextFieldItem = (props: props) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const toaster = useToaster();
  let isAdmin = false;
  if (user && isAuthenticated) {
    const roles = user['auth-roles'];
    isAdmin = roles.includes('super-admin');
  }

  const { field, productId } = props;

  const isNew = field === undefined;

  const emptyTextField = {
    name: '',
    productId: productId,
    text: '',
  } as TextField;

  const [showAlert, setShowAlert] = useState(false);
  const [editedField, setEditedField] = useState<TextField>(field ?? emptyTextField);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleChange = (field: keyof TextField, value: any) => {
    setEditedField((editedField) => ({
      ...editedField,
      [field]: value,
    }));
  };

  const addField = async () => {
    setSubmitLoading(true);
    const token = await getAccessTokenSilently();
    try {
      const id = await addTextField(token, editedField, productId);
      const newField = { ...editedField, id: id };
      setEditedField(newField);

      props.setFields((oldFields: TextField[]) => [...oldFields, newField]);
      setEditedField(emptyTextField);
      toaster.push(
        <Message showIcon type="info" closable>
          {'Added field!'}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
      setSubmitLoading(false);
    } catch (error) {
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
      setSubmitLoading(false);
    }
  };

  const saveField = async () => {
    setSubmitLoading(true);
    const token = await getAccessTokenSilently();
    try {
      const id = await updateTextField(token, editedField, productId);
      toaster.push(
        <Message showIcon type="info" closable>
          {'Added field!'}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
      setSubmitLoading(false);
    } catch (error) {
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
      setSubmitLoading(false);
    }
  };

  const deleteField = async () => {
    setShowAlert(false);
    setDeleteLoading(true);
    const token = await getAccessTokenSilently();

    try {
      const response = await deleteTextField(token, editedField.id, productId);
      console.log(response);
      props.setFields((oldFields: TextField[]) => {
        const arr = oldFields.filter((field) => field.id !== response);
        console.log(arr);
        return arr;
      });
      toaster.push(
        <Message showIcon type="info" closable>
          {'Deleted field!'}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
      setDeleteLoading(false);
    } catch (error) {
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
      setDeleteLoading(false);
    }
  };

  return (
    <Panel
      header={isNew ? 'New field' : editedField.name === '' ? 'No name' : editedField.name}
      collapsible
      bordered
      defaultExpanded={isNew || !isAdmin}
    >
      <Modal backdrop="static" role="alertdialog" open={showAlert} onClose={() => setShowAlert(false)} size="xs">
        <Modal.Body>
          <RemindIcon style={{ color: '#ffb300', fontSize: 24 }} />
          <p>The field will be deleted permanently!</p>
          <p>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteField} color="red" appearance="primary">
            Delete!
          </Button>
          <Button onClick={() => setShowAlert(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {isAdmin ? (
        <InputGroup style={{ marginBottom: '10px' }}>
          <InputGroup.Addon>Name</InputGroup.Addon>
          <Input value={editedField.name} onChange={(value) => handleChange('name', value)} />
        </InputGroup>
      ) : (
        <></>
      )}

      <InputGroup style={{ marginBottom: '10px' }}>
        <InputGroup.Addon>Text</InputGroup.Addon>
        <Input
          as="textarea"
          readOnly={!isAdmin}
          rows={5}
          value={editedField.text}
          onChange={(value) => handleChange('text', value)}
        />
      </InputGroup>

      {isAdmin ? (
        <div style={{ display: 'flex' }}>
          {!isNew ? (
            <>
              <div style={{ flex: 1 }} />
              <Button loading={deleteLoading} appearance="primary" color="red" onClick={() => setShowAlert(true)}>
                Delete
              </Button>
            </>
          ) : (
            <></>
          )}
          <div style={{ flex: 1 }} />
          <Button
            loading={submitLoading}
            appearance="primary"
            onClick={() => {
              if (isNew) addField();
              else saveField();
            }}
          >
            {!isNew ? 'Save' : 'Add'}
          </Button>
          <div style={{ flex: 1 }} />
        </div>
      ) : (
        <></>
      )}
    </Panel>
  );
};

export default TextFieldItem;
