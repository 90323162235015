import { Table, Button, useToaster, Loader, Message, Pagination, Checkbox } from 'rsuite';
import { useEffect, useState } from 'react';
import { getProducts } from '../../api/RequestHandler';
import { Product, ProductFilter } from '../../interfaces';
import { useAuth0 } from '@auth0/auth0-react';
import DetailedProductModal from './DetailedProductModal';
import { SortType } from 'rsuite/esm/Table';
import { convertISODateStringToYYYYMMDD } from './DetailedProductMeta';

interface props {
  perPage: number;
  filter: ProductFilter;
  checkedKeys: string[];
  setCheckedKeys: Function;
  productsLoading: boolean;
  setProductsLoading: Function;
  activePage: number;
  setActivePage: Function;
}

const ProductTable = (props: props) => {
  const { Column, HeaderCell, Cell } = Table;
  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();

  const [products, setProducts] = useState<Product[]>([]);

  const [modalProduct, setModalProduct] = useState<Product | undefined>(undefined);
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);

  const [total, setTotal] = useState(0);
  const limit = props.perPage;

  let checked = false;
  let indeterminate = false;

  let matchingKeysCount = 0;

  props.checkedKeys.forEach((key: string) => {
    if (products.some((product) => product.id === key)) {
      matchingKeysCount++;
    }
  });

  if (matchingKeysCount === products.length) {
    checked = true;
  } else if (matchingKeysCount === 0) {
    checked = false;
  } else if (matchingKeysCount > 0 && matchingKeysCount < products.length) {
    indeterminate = true;
  }

  const handleCheckAll = (value: string, checked: boolean) => {
    if (checked) {
      // Check all the keys in the products array
      const keys = Array.from(new Set([...props.checkedKeys, ...products.map((item) => item.id)]));
      props.setCheckedKeys(keys);
    } else {
      // Uncheck only the keys that are currently in products
      const keys = props.checkedKeys.filter((key) => !products.some((product) => product.id === key));
      props.setCheckedKeys(keys);
    }
  };

  const handleCheck = (value: string, checked: boolean) => {
    const keys = checked ? [...props.checkedKeys, value] : props.checkedKeys.filter((item) => item !== value);
    props.setCheckedKeys(keys);
  };

  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortType, setSortType] = useState<SortType>('asc');

  const successMessage = (
    <Message showIcon type="info" closable>
      Fetched product data successfully
    </Message>
  );

  useEffect(() => {
    if (props.productsLoading) {
      getAccessTokenSilently().then(async (token) => {
        try {
          const x = props.filter;
          x.limit = limit.toString();
          x.offset = (limit * (props.activePage - 1)).toString();

          const response = await getProducts(token, x, sortColumn, sortType);
          setTotal(response.count);
          setProducts(response.products);
          toaster.push(successMessage, {
            placement: 'bottomEnd',
            duration: 2000,
          });
        } catch (error) {
          setProducts([]);
          toaster.push(
            <Message showIcon type="error" closable>
              {'' + error}
            </Message>,
            { placement: 'bottomEnd', duration: 2000 }
          );
        }
        props.setProductsLoading(false);
      });
    }
  });

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    props.setProductsLoading(true);
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  if (props.productsLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <Table
        autoHeight={true}
        data={products}
        bordered={true}
        fillHeight={false}
        cellBordered={true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column width={50} align="center">
          <HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: '40px' }}>
              <Checkbox inline checked={checked} indeterminate={indeterminate} onChange={handleCheckAll as any} />
            </div>
          </HeaderCell>
          <Cell style={{ padding: 0 }}>
            {(rowData) => {
              return (
                <div style={{ lineHeight: '46px' }}>
                  <Checkbox
                    value={rowData['id']}
                    inline
                    onChange={handleCheck as any}
                    checked={props.checkedKeys.some((item) => item === rowData['id'])}
                  />
                </div>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} width={200} sortable>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>

        <Column flexGrow={1} width={200} sortable>
          <HeaderCell>Date</HeaderCell>
          <Cell dataKey="date">
            {(rowData) => {
              return <div>{rowData.date ? convertISODateStringToYYYYMMDD(rowData.date) : 'No date'}</div>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} width={300} sortable>
          <HeaderCell>Company</HeaderCell>
          <Cell dataKey="company" />
        </Column>
        <Column flexGrow={1} width={300} sortable>
          <HeaderCell>Industry</HeaderCell>
          <Cell dataKey="industry" />
        </Column>
        <Column flexGrow={1} width={300} sortable>
          <HeaderCell>Type</HeaderCell>
          <Cell dataKey="type" />
        </Column>
        <Column flexGrow={1} width={300} sortable>
          <HeaderCell>Country</HeaderCell>
          <Cell dataKey="country" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>...</HeaderCell>
          <Cell style={{ padding: '6px' }}>
            {(rowData) => {
              return (
                <Button
                  appearance="link"
                  onClick={() => {
                    setModalProduct(products.find((product) => product.id === rowData.id));
                    setModalLoading(true);
                    handleOpen();
                  }}
                >
                  View more
                </Button>
              );
            }}
          </Cell>
        </Column>
      </Table>

      <hr />
      <Pagination
        layout={['total', '-', 'pager', 'skip']}
        size={'md'}
        prev={true}
        next={true}
        first={true}
        last={true}
        ellipsis={true}
        boundaryLinks={true}
        total={total}
        maxButtons={5}
        limit={limit}
        activePage={props.activePage}
        onChangePage={(page) => {
          props.setActivePage(page);
          props.setProductsLoading(true);
        }}
      />

      <DetailedProductModal
        open={open}
        setOpen={setOpen}
        modalLoading={modalLoading}
        setModalLoading={setModalLoading}
        modalProduct={modalProduct}
        setModalProduct={setModalProduct}
      />
    </>
  );
};

export default ProductTable;
