import { Button, useToaster, Loader, Message, Modal } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import { Product } from '../../interfaces';
import { getDetailedProduct } from '../../api/RequestHandler';
import DetailedProductMeta from './DetailedProductMeta';
import DetailedProductTextFields from './DetailedProductTextFields';

interface props {
  open: boolean;
  setOpen: Function;
  modalProduct: Product | undefined;
  setModalProduct: Function;
  modalLoading: boolean;
  setModalLoading: Function;
}

const DetailedProductModal = (props: props) => {
  const close = () => {
    setTimeout(() => {
      props.setOpen(false);
    }, 500);
  };

  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();

  const handleClose = () => {
    props.setOpen(false);
    setTimeout(() => {
      props.setModalLoading(true);
    }, 350);
  };

  if (props.modalProduct === undefined) {
    return <></>;
  }
  const product = props.modalProduct;
  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      onOpen={async () => {
        try {
          const token = await getAccessTokenSilently();
          const products = (await getDetailedProduct(token, props.modalProduct!.id)) as unknown as Product[];
          if (products.length === 1) {
            props.setModalProduct(products[0]);
            props.setModalLoading(false);
          } else {
            close();
            throw new Error('No product found!');
          }

          toaster.push(
            <Message showIcon type="info" closable>
              Fetched detailed product
            </Message>,
            { placement: 'bottomEnd', duration: 2000 }
          );
        } catch (error) {
          toaster.push(
            <Message showIcon type="error" closable>
              {'' + error}
            </Message>,
            { placement: 'bottomEnd', duration: 2000 }
          );
          close();
        }
      }}
    >
      <Modal.Header>
        <Modal.Title>{props.modalProduct.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!props.modalLoading ? (
          <div>
            <DetailedProductMeta product={product} />
            <div style={{ height: '25px' }}></div>
            <DetailedProductTextFields product={product} />
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Loader size="md" />
          </div>
        )}
        <div style={{ height: '80px' }}></div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="subtle">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailedProductModal;
