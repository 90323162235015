import Categories from '../components/admin/Categories';
import ScraperConfigs from '../components/admin/ScraperConfigs';

interface props {}

const Admin = (props: props) => {
  return (
    <div>
      <ScraperConfigs></ScraperConfigs>
      <hr></hr>
      <Categories></Categories>
    </div>
  );
};
export default Admin;
