import { Product } from '../interfaces';

interface BoxPlotData {
  x: string;
  y: number[];
}

interface Series {
  name: string;
  type: string;
  data: BoxPlotData[];
}

export const DetailedProductToSeries = (products: Product[], categoryId: string | null): Series[] => {
  if (!categoryId) {
    return [];
  }
  const series = [];
  series.push({
    name: "Show Boxes",
    type: "boxPlot",
    data: DetailedProductToBoxPlot(products, categoryId),
  });

  products.forEach((product) => {
    const data = [] as BoxPlotData[];

    product.productData?.forEach((pData) => {
      const validDataPoints =
        pData.dataPoints?.filter((dp) => !isNaN(Number(dp.value)) && dp.category === categoryId) || [];
      validDataPoints.sort((point1, point2) => (point1.productStage < point2.productStage ? -1 : 1));

      validDataPoints.forEach((dataPoint) => {
        data.push({ x: dataPoint.productStage, y: [dataPoint.value] });
      });
    });

    const serie = {
      name: product.name,
      type: 'scatter',
      data: data,
    };
    series.push(serie);
  });
  return series;
};

export const DetailedProductToBoxPlot = (products: Product[], categoryId: string | null): BoxPlotData[] => {
  if (!categoryId) {
    return [];
  }

  const stageDataMap: { [stage: string]: number[] } = {};

  products.forEach((product) => {
    product.productData?.forEach((productData) => {
      const validDataPoints =
        productData.dataPoints?.filter((dp) => !isNaN(Number(dp.value)) && dp.category === categoryId) || [];

      validDataPoints.forEach((dataPoint) => {
        if (!stageDataMap[dataPoint.productStage]) {
          stageDataMap[dataPoint.productStage] = [];
        }
        stageDataMap[dataPoint.productStage].push(dataPoint.value);
      });
    });
  });

  const sortedStages = Object.keys(stageDataMap).sort();

  const plotData: BoxPlotData[] = sortedStages.map((stage) => {
    const dataPoints = stageDataMap[stage];
    const sortedData = dataPoints.sort((a, b) => a - b);
    const q1 = quantile(sortedData, 0.25);
    const median = quantile(sortedData, 0.5);
    const q3 = quantile(sortedData, 0.75);

    return {
      x: stage,
      y: [Math.min(...sortedData), q1, median, q3, Math.max(...sortedData)],
    };
  });

  return plotData;
};

function quantile(arr: number[], q: number) {
  const sorted = arr.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
}
