import { Panel } from 'rsuite';
interface props {
  title: string;
  element: JSX.Element;
}

const Layout = (props: props) => {
  return (
    <div
      style={{
        width: '100%',
        overflowY: 'auto',
        padding: '60px 40px 20px 40px',
        overflowWrap: 'break-word',
      }}
    >
      <h2>{props.title}</h2>
      <Panel shaded bordered>
        {props.element}
      </Panel>
    </div>
  );
};
export default Layout;
