import { useAuth0 } from '@auth0/auth0-react';
import { Navbar, Nav } from 'rsuite';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import AdminIcon from '@rsuite/icons/Admin';
import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';
import MemberIcon from '@rsuite/icons/Member';
import BarLineChartIcon from '@rsuite/icons/BarLineChart';
import PageIcon from '@rsuite/icons/Page';
import ExitIcon from '@rsuite/icons/Exit';

import 'rsuite/dist/rsuite.min.css';

const Header = () => {
  const { isAuthenticated, logout, loginWithRedirect, user } = useAuth0();
  const [path, setPath] = useState<string>('');
  const location = useLocation();

  let isAdmin = false;
  if (user) {
    const roles = user['auth-roles'];
    isAdmin = roles.includes('super-admin');
  }

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  return (
    <div style={{ position: 'fixed', width: '100%', userSelect: 'none', zIndex: '999' }}>
      <Navbar>
        <Navbar.Brand style={{ padding: '0px' }}>
          <img
            alt="Logo"
            style={{ objectFit: 'contain', height: '100%', userSelect: 'none' }}
            src="/logo-full.svg"
          ></img>
        </Navbar.Brand>
        <Nav>
          <Link to="/">
            <Nav.Item href="/" active={path === '/'} icon={<HomeIcon />}>
              Home
            </Nav.Item>
          </Link>
          <Link to="/products">
            <Nav.Item href="/products" active={path === '/products'} icon={<PageIcon />}>
              EPDs
            </Nav.Item>
          </Link>
          <Link to="/graphs">
            <Nav.Item href="/graphs" active={path === '/graphs'} icon={<BarLineChartIcon />}>
              EPD analyzer
            </Nav.Item>
          </Link>
          <Link to="/upload">
            <Nav.Item href="/upload" active={path === '/upload'} icon={<CogIcon />}>
              Upload
            </Nav.Item>
          </Link>
        </Nav>
        <Nav pullRight>
          {isAuthenticated ? (
            <Nav.Menu title="Settings" icon={<CogIcon />}>
              <Link to="/profile">
                <Nav.Item icon={<MemberIcon />}>Profile</Nav.Item>
              </Link>
              {isAdmin ? (
                <Link to="/admin">
                  <Nav.Item href="/admin" active={path === '/admin'} icon={<AdminIcon />}>
                    Admin
                  </Nav.Item>
                </Link>
              ) : (
                <></>
              )}
              <Nav.Item
                icon={<ExitIcon />}
                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
              >
                Logout
              </Nav.Item>
            </Nav.Menu>
          ) : (
            <Nav.Item icon={<MemberIcon />} onClick={() => loginWithRedirect()}>
              Login
            </Nav.Item>
          )}
        </Nav>
      </Navbar>
    </div>
  );
};

export default Header;
