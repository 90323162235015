import { Category, Product, ProductFilter, ProductWithCount, ScrapeConfig, TextField } from '../interfaces';

export const isProd = true;

const url = isProd ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:8080';
export const getProducts = async (
  jwt: string,
  filter: ProductFilter,
  sortColumn: string,
  sortType: string
): Promise<ProductWithCount> => {
  const x = new URLSearchParams(filter as any);
  const params = new URLSearchParams();

  x.forEach((value, key) => {
    if (value !== 'null' && value !== 'undefined') {
      params.append(key, value);
    }
  });
  const response = await fetch(url + `/products?${params}&sortBy=${sortColumn}&sortOrder=${sortType}`, {
    method: 'GET',
    headers: { authorization: 'Bearer ' + jwt },
  });
  if (!response.ok) {
    const data = await response.json();
    const error = data.error ? data.error : 'Unknown error';
    throw new Error(error);
  }
  return response.json();
};

export const getDetailedProduct = async (jwt: string, productId: string): Promise<Product> => {
  const response = await fetch(url + `/detailedProducts`, {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + jwt,
      'product-ids': productId,
    },
  });

  if (!response.ok) {
    const data = await response.json();
    const error = data.error ? data.error : 'Unknown error';
    throw new Error(error);
  }

  return response.json();
};

export const getDetailedProducts = async (jwt: string, productIds: string[]): Promise<Product[]> => {
  const response = await fetch(url + `/detailedProducts`, {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + jwt,
      'product-ids': productIds.join(','),
    },
  });
  if (!response.ok) {
    const data = await response.json();
    const error = data.error ? data.error : 'Unknown error';
    throw new Error(error);
  }
  return response.json();
};

export const getCategories = async (jwt: string): Promise<Category[]> => {
  const response = await fetch(url + `/categories`, {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + jwt,
    },
  });
  if (!response.ok) {
    const data = await response.json();
    const error = data.error ? data.error : 'Unknown error';
    throw new Error(error);
  }
  return response.json();
};

export const updateCategory = async (jwt: string, category: Category) => {
  const response = await fetch(url + `/categories/${category.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
    body: JSON.stringify(category),
  });
  if (!response.ok) {
    throw new Error('Failed to update');
  }
  return;
}


export const deleteCategory = async (jwt: string, category: Category) => {
  const response = await fetch(url + `/categories/${category.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to delete category');
  }
  return await response.json();
}

export const createCategory = async (jwt: string, category: Category) => {
  const response = await fetch(url + `/categories/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
    body: JSON.stringify(category),
  });
  if (!response.ok) {
    throw new Error('Failed to create category');
  }
  return;
}




export const getScrapeConfigs = async (jwt: string): Promise<ScrapeConfig[]> => {
  const response = await fetch(url + `/scrapeConfigs`, {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + jwt,
    },
  });
  if (!response.ok) {
    const data = await response.json();
    const error = data.error ? data.error : 'Unknown error';
    throw new Error(error);
  }
  return response.json();
};

export const updateScrapeConfig = async (jwt: string, config: ScrapeConfig) => {
  const response = await fetch(url + `/scrapeConfigs/${config.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
    body: JSON.stringify(config),
  });
  if (!response.ok) {
    throw new Error('Failed to update');
  }
  return;
};

export const deleteScrapeConfig = async (jwt: string, config: ScrapeConfig) => {
  const response = await fetch(url + `/scrapeConfigs/${config.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to update');
  }
  return await response.json();
};

export const createScrapeConfig = async (jwt: string, config: ScrapeConfig) => {
  const response = await fetch(url + `/scrapeConfigs/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
    body: JSON.stringify(config),
  });
  if (!response.ok) {
    throw new Error('Failed to update');
  }
  return response.json();
};

export const updateProduct = async (jwt: string, product: Product) => {
  const liteProduct = product;
  liteProduct.productData = undefined;
  const response = await fetch(url + `/products/${product.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
    body: JSON.stringify(liteProduct),
  });
  if (!response.ok) {
    throw new Error('Failed to update');
  }
  return;
};

export const addTextField = async (jwt: string, textField: TextField, productId: string) => {
  const response = await fetch(`${url}/products/${productId}/textFields`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
    body: JSON.stringify(textField),
  });
  if (!response.ok) {
    throw new Error('Failed to update');
  }
  return response.json();
};

export const deleteTextField = async (jwt: string, textFieldId: string, productId: string) => {
  const response = await fetch(`${url}/products/${productId}/textFields/${textFieldId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to delete');
  }
  return response.json();
};

export const updateTextField = async (jwt: string, textField: TextField, productId: string) => {
  const response = await fetch(`${url}/products/${productId}/textFields/${textField.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + jwt,
    },
    body: JSON.stringify(textField),
  });
  if (!response.ok) {
    throw new Error('Failed to delete');
  }
  return response.json();
};

export const getSignedURL = async (jwt: string, fileNameToUse: string, fileType: any, metadata: any) => {
    const response = await fetch(`${url}/upload/signedUrl`, {
      method: 'POST',
      body: JSON.stringify({ fileName: fileNameToUse, contentType: fileType, metadata: metadata }),
      headers: { 'content-type': 'application/json', authorization: 'Bearer ' + jwt },
    });
    if (!response.ok) {
    throw new Error('Failed to delete');
    }
    return response.json();
  }
