import { Panel } from 'rsuite';
import ProductTable from '../components/products/ProductTable';
import ChartContainer from '../components/ChartContainer';
import { useState } from 'react';
import FilterBox from '../components/filter/FilterBox';
import { InputItem } from '../components/filter/CustomSelectPicker';

const Graphs = () => {
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<InputItem | null>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const [filter, setFilter] = useState<any>({});

  const [productsLoading, setProductsLoading] = useState<boolean>(true);

  return (
    <div style={{ display: 'flex' }}>
      <Panel shaded bordered style={{ marginRight: '20px', width: '300px' }}>
        <div>
          <FilterBox
            setProductsLoading={setProductsLoading}
            filter={filter}
            setFilter={setFilter}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setActivePage={setActivePage}
          ></FilterBox>
        </div>
      </Panel>
      <div style={{ flex: 1 }}>
        <Panel shaded bordered style={{ minHeight: '350px' }}>
          <ChartContainer
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            selectedCategory={selectedCategory}
          />
        </Panel>
        <div style={{ height: '20px' }}></div>
        <Panel shaded bordered style={{ minHeight: '350px' }}>
          <ProductTable
            perPage={5}
            filter={filter}
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            productsLoading={productsLoading}
            setProductsLoading={setProductsLoading}
            setActivePage={setActivePage}
            activePage={activePage}
          />
        </Panel>
      </div>
    </div>
  );
};

export default Graphs;
