import { SetStateAction, useEffect, useState, useCallback, useRef } from "react";
import { Button, DateRangePicker, Input, InputGroup, Message, SelectPicker, useToaster } from "rsuite";
import { Category, ProductFilter } from "../../interfaces";
import { useAuth0 } from "@auth0/auth0-react";
import { getCategories } from "../../api/RequestHandler";
import CustomSelectPicker, { InputItem } from "./CustomSelectPicker";



interface Props {
  filter: ProductFilter;
  setFilter: React.Dispatch<SetStateAction<ProductFilter>>;
  selectedCategory?: InputItem | null;
  setSelectedCategory?: React.Dispatch<SetStateAction<InputItem | null>>;
  setProductsLoading: React.Dispatch<SetStateAction<boolean>>;
  setActivePage: React.Dispatch<SetStateAction<number>>;
}

const FilterBox: React.FC<Props> = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();

  const [categories, setCategories] = useState<Category[]>([]);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(true);

  const nameRef = useRef<HTMLInputElement | null>(null);
  const companyRef = useRef<HTMLInputElement | null>(null);
  const typeRef = useRef<HTMLInputElement | null>(null);
  const countryRef = useRef<HTMLInputElement | null>(null);
  const industryRef = useRef<HTMLInputElement | null>(null);

  const categoryData = categories.map((item) => ({
    label: item.name,
    value: item.id,
    description: item.description
  }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetFilter = debounce((newFilter) => {
    props.setFilter(newFilter);
    props.setActivePage(1);
    props.setProductsLoading(true);
  }, 500);

  const clearFilter = () => {
    props.setFilter({});
    if (nameRef.current) nameRef.current.value = '';
    if (companyRef.current) companyRef.current.value = '';
    if (typeRef.current) typeRef.current.value = '';
    if (countryRef.current) countryRef.current.value = '';
    if (industryRef.current) industryRef.current.value = '';
    props.setActivePage(1);
    props.setProductsLoading(true);
  };
  useEffect(() => {
    if (loadingCategories && props.setSelectedCategory !== undefined) {
      try {
        getAccessTokenSilently().then(async (token) => {
          try {
            const response = await getCategories(token);
            if (response) {
              setCategories(response);
              setLoadingCategories(false);
            } else {
              throw new Error('Unable to get categories');
            }
          } catch (error) {
            toaster.push(
              <Message showIcon type="error" closable>
                {'' + error}
              </Message>,
              { placement: 'bottomEnd', duration: 2000 }
            );
          }
        });
      } catch (error) {
        toaster.push(
          <Message showIcon type="info" closable>
            Fetched graph data
          </Message>,
          { placement: 'bottomEnd', duration: 2000 }
        );
      }
    }
  }, [loadingCategories, getAccessTokenSilently, props.setSelectedCategory, toaster, props]);

  const handleInputChange = (key: keyof ProductFilter, value: string) => {
    const updatedFilter = { ...props.filter, [key]: value };
    debounceSetFilter(updatedFilter);
  };

  const handleDateRangeChange = (value: Date[]) => {
    const updatedFilter = { ...props.filter };
    if (value) {
      updatedFilter.fromDate = value[0].toISOString();
      updatedFilter.toDate = value[1].toISOString();
    } else {
      updatedFilter.fromDate = null;
      updatedFilter.toDate = null;
    }
    debounceSetFilter(updatedFilter);
  };

  return (
    <div>
      {!props.setSelectedCategory ? <></> : 
      <div>
        <b>Select KPI</b>
        <br></br>
        <CustomSelectPicker label="KPI" isLoading={loadingCategories}
          items={categoryData} selectedItem={props.selectedCategory!} setSelectedItem={props.setSelectedCategory}/>

        {/*<SelectPicker
          label="KPI"
          data={categoryData}
          style={{ width: 224 }}
          onClean={() => {props.setSelectedCategory!(null)}}
          loading={loadingCategories}
          onChange={categoryChange as any}
  />*/}
        <hr />
        <b>EPD filter</b>
        <DateRangePicker
          hoverRange="month"
          onChange={handleDateRangeChange as any}
        />
        <div style={{ height: "20px" }}></div>
      </div>}

      <InputGroup>
        <InputGroup.Addon>EPD Name</InputGroup.Addon>
        <Input ref={nameRef} onChange={(value) => handleInputChange('name', value)} />
      </InputGroup>
      <div style={{ height: '20px' }}></div>

      <InputGroup>
        <InputGroup.Addon>Company</InputGroup.Addon>
        <Input ref={companyRef} onChange={(value) => handleInputChange('company', value)} />
      </InputGroup>
      <div style={{ height: '20px' }}></div>

      <InputGroup>
        <InputGroup.Addon>Product Type</InputGroup.Addon>
        <Input ref={typeRef} onChange={(value) => handleInputChange('type', value)} />
      </InputGroup>
      <div style={{ height: '20px' }}></div>

      <InputGroup>
        <InputGroup.Addon>Country</InputGroup.Addon>
        <Input ref={typeRef} onChange={(value) => handleInputChange('country', value)} />
      </InputGroup>
      <div style={{ height: '20px' }}></div>

      <InputGroup>
        <InputGroup.Addon>Industry</InputGroup.Addon>
        <Input ref={typeRef} onChange={(value) => handleInputChange('industry', value)} />
      </InputGroup>
      <div style={{ height: '20px' }}></div>

      <Button appearance="primary" onClick={clearFilter}>
        Clear filter
      </Button>
    </div>
  );
};

// Utility debounce function
const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default FilterBox;
