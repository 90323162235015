import { useEffect, useState } from 'react';
import { ScrapeConfig } from '../../interfaces';
import { Loader, Message, Panel, useToaster } from 'rsuite';
import ScraperConfigItem, { ScraperConfigItemNew } from './ScraperConfigItem';
import { useAuth0 } from '@auth0/auth0-react';
import { getScrapeConfigs } from '../../api/RequestHandler';

const ScraperConfigs = () => {
  const toaster = useToaster();
  const { getAccessTokenSilently } = useAuth0();

  const [scraperConfigs, setScraperConfigs] = useState<ScrapeConfig[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loading) {
      try {
        getAccessTokenSilently().then(async (token) => {
          const response = await getScrapeConfigs(token);

          setScraperConfigs(response);
          setLoading(false);
        });
      } catch (error) {
        toaster.push(
          <Message showIcon type="error" closable>
            {'' + error}
          </Message>,
          { placement: 'bottomEnd', duration: 2000 }
        );
      }
    }
  });

  const items_per_row = 3;
  const paddingSize = 20;

  // Calculate the width of each item and include the paddings
  const itemWidth = `calc((100% - ${paddingSize * items_per_row}px) / ${items_per_row})`;

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' }}>
      <Panel shaded bordered style={{ width: '100%' }}>
        <h3>Scraper config</h3>
        <ScraperConfigItemNew setConfigs={setScraperConfigs} configs={scraperConfigs} itemWidth={''} />
        {!loading ? (
          <div style={{ padding: '10px', display: 'flex', flexWrap: 'wrap' }}>
            {scraperConfigs.map((config, index) => (
              <div key={index} style={{ flex: `0 0 ${itemWidth}`, margin: '10px' }}>
                <ScraperConfigItem
                  key={config.id}
                  config={config}
                  setConfigs={setScraperConfigs}
                  itemWidth={itemWidth}
                ></ScraperConfigItem>
              </div>
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </Panel>
    </div>
  );
};

export default ScraperConfigs;
