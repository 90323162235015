import { Product } from '../../interfaces';
import { Button, Input, InputGroup, Message, Panel, useToaster } from 'rsuite';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { updateProduct } from '../../api/RequestHandler';
import DetailIcon from '@rsuite/icons/Detail';

interface Props {
  product: Product;
}

const DetailedProductMeta: React.FC<Props> = (props: Props) => {
  const { product } = props;

  const [editedProduct, setEditedProduct] = useState<Product>({ ...product });

  const [loading, setLoading] = useState<boolean>(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const toaster = useToaster();

  const handleSubmit = () => {
    setLoading(true);
    try {
      getAccessTokenSilently()
        .then(async (token) => {
          try {
            await updateProduct(token, editedProduct).catch((error) => {
              throw new Error(error);
            });
            setLoading(false);
            toaster.push(
              <Message showIcon type="info" closable>
                {'Updated product'}
              </Message>,
              { placement: 'bottomEnd', duration: 2000 }
            );
          } catch (error) {
            toaster.push(
              <Message showIcon type="error" closable>
                {'' + error}
              </Message>,
              { placement: 'bottomEnd', duration: 2000 }
            );
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    } catch (error) {
      toaster.push(
        <Message showIcon type="error" closable>
          {'' + error}
        </Message>,
        { placement: 'bottomEnd', duration: 2000 }
      );
    }
  };

  const handleChange = (field: keyof Product, value: string | Date) => {
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [field]: value instanceof Date ? value.toISOString() : value,
    }));
  };

  let isAdmin = false;
  if (user && isAuthenticated) {
    const roles = user['auth-roles'];
    isAdmin = roles.includes('super-admin');
  }

  return (
    <Panel bordered>
      <h3>Details</h3>
      <InputItem
        type="name"
        value={editedProduct.name}
        onChange={(value) => handleChange('name', value)}
        disabled={!isAdmin}
      />
      <div style={{ height: '20px' }} />
      <InputItem
        type="company"
        value={editedProduct.company}
        onChange={(value) => handleChange('company', value)}
        disabled={!isAdmin}
      />
      <div style={{ height: '20px' }} />
      <InputItem
        type="industry"
        value={editedProduct.industry ?? ''}
        onChange={(value) => handleChange('industry', value)}
        disabled={!isAdmin}
      />
      <div style={{ height: '20px' }} />
      <InputItem
        type="type"
        value={editedProduct.type}
        onChange={(value) => handleChange('type', value)}
        disabled={!isAdmin}
      />
      <div style={{ height: '20px' }} />

      <InputItem
        type="validUntil"
        value={editedProduct.validUntil ?? ''}
        onChange={(value) => handleChange('validUntil', value)}
        disabled={!isAdmin}
      />
      <div style={{ height: '20px' }} />
      <InputItem
        type="marketArea"
        value={editedProduct.marketArea ?? ''}
        onChange={(value) => handleChange('marketArea', value)}
        disabled={!isAdmin}
      />
      <div style={{ height: '20px' }} />
      <InputItem
        type="country"
        value={editedProduct.country ?? ''}
        onChange={(value) => handleChange('country', value)}
        disabled={!isAdmin}
      />
      <div style={{ height: '20px' }} />
      <InputItem
        type="language"
        value={editedProduct.language ?? ''}
        onChange={(value) => handleChange('language', value)}
        disabled={!isAdmin}
      />
      <div style={{ height: '20px' }} />

      <InputItem
        type="date"
        value={editedProduct.date ?? ''}
        onChange={(value) => handleChange('date', value)}
        disabled={!isAdmin}
      />

      <div style={{ height: '20px' }} />
      {editedProduct.sourceUrl && (
        <Button
          appearance="link"
          href={editedProduct.sourceUrl}
          target="_blank"
          endIcon={<DetailIcon />}
          style={{ paddingLeft: '0' }}
        >
          Open EPD
        </Button>
      )}

      <div style={{ height: '20px' }} />
      {!isAdmin ? (
        <></>
      ) : (
        <Button loading={loading} appearance="primary" onClick={handleSubmit}>
          Save
        </Button>
      )}
    </Panel>
  );
};

interface InputItemProps {
  type: keyof Product;
  value: string;
  onChange: (value: string | Date) => void;
  disabled: boolean;
}

const InputItem: React.FC<InputItemProps> = ({ type, value, onChange, disabled }) => {
  return (
    <InputGroup>
      <InputGroup.Addon>{type.charAt(0).toUpperCase() + type.slice(1)}</InputGroup.Addon>
      {type === 'date' || type === 'validUntil' ? (
        <Input
          type="date"
          value={convertISODateStringToYYYYMMDD(value)}
          onChange={(value) => onChange(new Date(value))}
          readOnly={disabled}
        />
      ) : (
        <Input value={value as string} onChange={(value) => onChange(value)} readOnly={disabled} />
      )}
    </InputGroup>
  );
};

export function convertISODateStringToYYYYMMDD(isoDateString: string) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export default DetailedProductMeta;
