import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Product } from '../interfaces';
import { Loader, useToaster, Message } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import { getDetailedProducts } from '../api/RequestHandler';
import { DetailedProductToSeries } from './DataCalculator';
import { InputItem } from './filter/CustomSelectPicker';
import './ChartContainer.scss';

interface Props {
  checkedKeys: string[];
  setCheckedKeys: Function;
  selectedCategory?: InputItem | null;
}

const ChartContainer: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [reloadChart, setReloadChart] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();
  useEffect(() => {
    setReloadChart(true);

    setTimeout(() => {
      setReloadChart(false);
    }, 200);
  }, [props.selectedCategory]);

  useEffect(() => {
    if (props.checkedKeys.length === 0) {
      // If there are no checked keys, set loading to false and empty the products array.
      setLoading(false);
      setProducts([]);
    } else {
      // If there are checked keys, set loading to true to trigger data fetching.
      setLoading(true);
    }
  }, [props.checkedKeys]);

  useEffect(() => {
    if (loading && props.checkedKeys.length > 0) {
      try {
        getAccessTokenSilently()
          .then(async (token) => {
            try {
              const results = await getDetailedProducts(token, props.checkedKeys);
              setProducts(results);
              setLoading(false);
              toaster.push(
                <Message showIcon type="info" closable>
                  Fetched graph data
                </Message>,
                { placement: 'bottomEnd', duration: 2000 }
              );
            } catch (error) {
              toaster.push(
                <Message showIcon type="error" closable>
                  {'' + error}
                </Message>,
                { placement: 'bottomEnd', duration: 2000 }
              );
            }
          })
          .catch((error) => {
            toaster.push(
              <Message showIcon type="error" closable>
                {'' + error}
              </Message>,
              { placement: 'bottomEnd', duration: 2000 }
            );
          });
      } catch (error) {
        toaster.push(
          <Message showIcon type="error" closable>
            {'' + error}
          </Message>,
          { placement: 'bottomEnd', duration: 2000 }
        );
      }
    }
  }, [loading, props.checkedKeys, toaster, getAccessTokenSilently]);

  const options: ApexOptions = {
    title: {
      text: props.selectedCategory?.label ?? 'No KPI selected',
      align: 'left',
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: '#AFE1AF',
          lower: '#50C878',
        },
      },
    },
    chart: {
      toolbar: {
        tools: {
          customIcons: [
            {
              icon: `<img src=/file-download.svg class=download-csv-icon>`,
              index: -1,
              title: 'Download CSV',
              class: 'custom-icon',
              click: (_chart, options, _e) => {
                const headerRow = ['stage', 'minimum', 'q1', 'median', 'q3', 'maximum'];

                const dataRows = options.config.series
                  .find((series: any) => series.type === 'boxPlot')
                  ?.data.map((data: any) => [data.x, ...data.y]);

                const csvContent = [headerRow, ...dataRows].map((row) => row.join(',')).join('\n');

                const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent);
                const link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                const fileNameSuffix = props.selectedCategory?.label ? `-${props.selectedCategory.label}` : '';
                link.setAttribute('download', `epd-data${fileNameSuffix}`);
                document.body.appendChild(link);
                link.click();
              },
            },
          ],
        },
        show: true,
      },
      id: 'chart',
      type: 'boxPlot',
      height: 300,
    },
  };

  const series = DetailedProductToSeries(products, props.selectedCategory?.value ?? null);

  if (loading || reloadChart) {
    return <Loader speed="fast" />;
  }

  return <Chart options={options as any} series={series} type="boxPlot" height={300} />;
};

export default ChartContainer;
