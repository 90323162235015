import React from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import Profile from './pages/Profile';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Products from './pages/Products';
import Home from './pages/Home';
import Graphs from './pages/Graphs';
import ProtectedComponent from './components/ProtectedComponent';
import Layout from './components/Layout';
import Admin from './pages/Admin';
import Upload from './pages/Upload';

function App() {
  const { isAuthenticated, user } = useAuth0();

  let isAdmin = false;
  if (user && isAuthenticated) {
    const roles = user['auth-roles'];
    isAdmin = roles.includes('super-admin');
  }

  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Layout title="Home" element={<Home />}></Layout>} />
        <Route
          path="/profile"
          element={<Layout title="Profile" element={<ProtectedComponent component={Profile} />} />}
        />
        <Route
          path="/products"
          element={<Layout title="EPDs" element={<ProtectedComponent component={Products} />} />}
        />
        <Route
          path="/graphs"
          element={<Layout title="EPD analyzer" element={<ProtectedComponent component={Graphs} />} />}
        />
        <Route
          path="/upload"
          element={<Layout title="Upload file" element={<ProtectedComponent component={Upload} />} />}
        />
        <Route path="/admin" element={<Layout title="Admin" element={<ProtectedComponent component={Admin} />} />} />
        <Route path="/*" element={<Layout title="404" element={<div>404 page not found</div>}></Layout>}></Route>
      </Routes>
    </div>
  );
}

export default App;
