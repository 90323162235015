import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, SelectPicker, Tooltip, Whisper } from 'rsuite';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
export interface InputItem {
  label: string;
  value: string;
  description?: string;
}

interface props {
  label: string;
  isLoading: boolean;
  items: InputItem[];
  setSelectedItem: Dispatch<SetStateAction<InputItem | null>>;
  selectedItem: InputItem | null;
}

const CustomSelectPicker = (props: props) => {
  const { isLoading, items, label, setSelectedItem, selectedItem } = props;
  const [isOpen, setIsOpen] = useState(false);

  const renderButton = (props: any, ref: any) => {
    const x = items.map((item) => ({ label: item.label, value: item.label }));
    const selected = x.find((x) => x.label === selectedItem?.label);

    return (
      <SelectPicker
        onab
        data={x}
        onClean={() => {
          setSelectedItem(null);
        }}
        value={selected ? selected.label : null}
        loading={isLoading}
        open={false}
        onOpen={() => {}}
        style={{ width: '100%' }}
        {...props}
        label={label}
      ></SelectPicker>
    );
  };

  const handleChange = (item: InputItem) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Dropdown
      open={isOpen}
      renderToggle={renderButton}
      title="Dropdown"
      ref={dropdownRef}
      onClick={() => {
        setIsOpen((old) => !old);
      }}
      style={{ width: '100%' }}
    >
      <div style={{ maxHeight: '200px', overflowY: 'auto', width: '100%' }}>
        {items.map((item) => (
          <Dropdown.Item style={{ minWidth: '150px' }} onClick={() => handleChange(item)}>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>{item.label}</div>
              <div>
                {item.description ? 
                <Whisper placement="top" controlId="control-id-click" trigger="hover" speaker={
                  <Tooltip>
                    {item.description ? item.description : ""}
                  </Tooltip>}>
                  <InfoOutlineIcon />
                </Whisper> : <></>}
              </div>
            </div>
          </Dropdown.Item>
        ))}
      </div>
    </Dropdown>
  );
};

export default CustomSelectPicker;
