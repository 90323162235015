import { useState } from 'react';
import ProductTable from '../components/products/ProductTable';
import { Panel } from 'rsuite';
import FilterBox from '../components/filter/FilterBox';
const Products = () => {
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

  const [activePage, setActivePage] = useState<number>(1);
  const [filter, setFilter] = useState<any>({});

  const [productsLoading, setProductsLoading] = useState<boolean>(true);

  return (
    <div style={{ display: 'flex' }}>
      <Panel shaded bordered style={{ marginRight: '20px', width: '300px' }}>
        <FilterBox
          setProductsLoading={setProductsLoading}
          filter={filter}
          setFilter={setFilter}
          setActivePage={setActivePage}
        ></FilterBox>
      </Panel>

      <Panel shaded bordered style={{ minHeight: '350px', flex: 1 }}>
        <ProductTable
          perPage={10}
          filter={filter}
          checkedKeys={checkedKeys}
          setCheckedKeys={setCheckedKeys}
          productsLoading={productsLoading}
          setProductsLoading={setProductsLoading}
          setActivePage={setActivePage}
          activePage={activePage}
        />
      </Panel>
    </div>
  );
};

export default Products;
